/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-extend-native */

Object.defineProperty(Object.prototype, 'only', {
  value(fields) {
    const obj = {};
    fields.forEach((x) => {
      if (this[x] !== undefined) {
        obj[x] = this[x];
      }
    });

    return obj;
  },
});
Object.defineProperty(Object.prototype, 'except', {
  value(fields) {
    const obj = JSON.parse(JSON.stringify(this));

    fields.forEach((x) => {
      if (this[x] !== undefined) {
        delete obj[x];
      }
    });

    return obj;
  },
});
// eslint-disable-next-line func-names
Array.prototype.flatten = function (depth = null) {
  let arr = this;

  if (depth > 1) {
    arr = arr.flatten(depth - 1);
  }

  return arr.reduce((flat, next) => flat.concat(next), []);
};

// eslint-disable-next-line func-names
Array.prototype.flatMap = function (callback, depth = null) {
  return this.flatten(depth).map(callback);
};
