import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
    texts_options: [],
    texts_fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, { data }) {
      state.options = data.map((x) => {
        const aux = x;
        aux.id = x.placement_question_id;
        aux.key = Math.random() * Date.now();
        aux.name = x.question.replace(/(<([^>]+)>)/ig, '');
        return aux;
      });
    },
    SET_FETCH_AT(state) {
      state.fetch_at = moment().valueOf();
    },
    SET_TEXTS_OPTIONS(state, { data }) {
      state.texts_options = data.map((x) => ({
        name: x.question.replace(/(<([^>]+)>)/ig, ''),
        key: Math.random() * Date.now(),
        id: x.placement_question_id,
      }));
    },
    SET_TEXTS_FETCH_AT(state) {
      state.texts_fetch_at = moment().valueOf();
    },
  },
  actions: {
    getOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.fetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('placement_test/questions', 'get',
            {
              per_page: 1000,
              active: true,
            },
            ({ data }) => {
              commit('SET_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
    getTextsOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.textsFetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('placement_test/questions', 'get',
            {
              per_page: 1000,
              only_texts: true,
            },
            ({ data }) => {
              commit('SET_TEXTS_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_TEXTS_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
  },
  getters: {
    fetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    textsFetchable(state) {
      return !state.texts_fetch_at || moment().valueOf() > state.texts_fetch_at + (30 * 1000);
    },
    options(state) {
      return state.options;
    },
    textsOptions(state) {
      return state.texts_options;
    },
  },
};
