const API = Object.freeze({
  LOGIN: 'login/',
  LOGOUT: 'logout/',

  USERS: 'users/',
  STUDENTS: 'students/',
  DEPARTMENTS: 'departments/',
  ROLES: 'roles/',
  AGENTS: 'agents/',
  PAYMENT_METHODS: 'payment_methods/',
  MONEYFLOW_CATEGORIES: 'moneyflow_categories/',
  PROGRAMMES: 'programmes/',
  PACKAGES: 'packages/',
  PACKAGE_ITEMS: 'package_items/',
  PACKAGE_ITEM_CATEGORIES: 'package_item_categories/',
  SCHEDULED_PAYMENTS: 'scheduled_payments/',
  MONEYFLOW: 'moneyflow/',
  MONEY_OUTFLOW: 'moneyflow/out',
  MONEY_INFLOW: 'moneyflow/in',
  COMISSIONS: 'comissions/',
  SALES_PER_COUNTRY: 'sales_per_country/',
  SOURCE_OF_SALES: 'source_of_sales/',
  INCOME_PROYECTION: 'income_projection/',
  COUNTRIES: 'countries/',
  MKT_SOURCES: 'marketing_sources/',
  LETTER_TEMPLATES: 'letter_templates/',
  AUTHORITIES: 'authorities/',
  ATTENDANCE_LIST: 'attendance/lists/',
  CONTINGENCY_MAP: 'contingency_map/',
  COURSES: 'courses/',
  CLASSROOMS: 'classrooms/',
  CLASSROOMS_NAME: 'classroom_names/',
  PERIODS: 'periods/',
  LEVELS: 'levels/',
  ASSESSMENTS: 'assessments/',
  ATTENDANCES: 'attendances/',
  TEACHERS: 'teachers/',
  FILES: 'files/',
  FILE_TYPES: 'file_types/',
  COURSE_PROGRESS: 'course_progress/',
  HOLIDAY_DAYS: 'holiday_days/',
  ASSESSMENT_ENROLMENT: 'assessment_enrolment/',
  STUDENT_ADDRESS: 'student_address/',
  PASSPORTS: 'passports/',
  ATTENDANCE_REPORT: 'attendance/report/',
  PLACEMENT_QUESTIONS: 'placement_test/questions/',
  PLACEMENT_EXAMS: 'placement_test/exams/',
  PLACEMENT_SCHEDULES: 'placement_test/schedules/',
  PLACEMENT_TEST: 'placement_test/student/',
  ENDPOINTS: 'endpoints/',
  HTML_TEMPLATES: 'html_templates/',
  INSURANCE_REPORT: 'insurances/report',
  VERSION: 'version/',
  WIDGETS: 'widgets/',
  USER_WIDGETS: 'user_widgets/',
  CHANGELOG: 'changelog/',
  STUDENTS_PER_COUNTRY: 'students_per_country/',
  PROGRAMMES_PER_LEVEL: 'programmes_per_level/',
  INCOME_PROJECTION_WIDGET: 'income_projection_widget/',
  PROGRAMMES_BY_STATUS: 'programmes_by_status/',
  PROGRAMMES_BY_CLASS_TIME: 'programmes_by_class_time/',
  OUTFLOW_BY_CATEGORY: 'outflow_by_category/',
  STUDENTS_STARTING: 'students_starting/',
  STUDENTS_FINISHING: 'students_finishing/',
  STUDENTS_ENROLMENT_EMPTY: 'students_no_enrolment/',
  STUDENTS_OVERPAID: 'students_overpaid/',
  FINISHED_STUDENTS_REPORT: 'finished_students/report/',
  STUDENTS_OVERASSISTED: 'students_overassisted',
  PAYMENTS_TO_APPROVE: 'payments_to_approve/',
  PAYMENTS_OVERDUE: 'payments_overdue/',
  CLASS_SCHEDULES: 'class_schedules/',
  FILE_TYPES_ADMIN: 'file_types/admin/',
  AGENT_TRANSFER_TRANSACTIONS: 'agent_transfer_transactions/',
  PROGRAMMES_CANCEL_REASONS_REPORT: 'programmes/cancel_reasons_report/',
  SCHOOL_SETTINGS: 'school_settings',
  LETTER_LAYOUTS: 'letter_layouts',
});

export default API;
