<template>
  <div>
    <FullPageLoader v-if="loading" />
    <router-view />

    <div id="modal-container" />
  </div>
</template>

<script>
import FullPageLoader from '@/components/FullPageLoader.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    FullPageLoader,
  },
  computed: {
    ...mapGetters(['loading']),
  },
  mounted() {
    this.$store.dispatch('school_settings/getSchoolSettings', {});
  },
};
</script>
