import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, { data }) {
      const format = (x) => moment(x).format(this.getters['school_settings/settings'].date_format);

      state.options = data.map((x) => ({
        ...x,
        id: x.package_payment_id,
        name: `Date: ${format(x.payment_date)} | Total: ${utils.methods.moneyFormat(x.total)} | Status: ${x.status}`,
      }));
    },
    SET_FETCH_AT(state) {
      state.fetch_at = moment().valueOf();
    },
  },
  actions: {
    getOptions({ commit, getters }, { cb, id, force }) {
      return new Promise(() => {
        if (force || getters.fetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request(`packages/${id}/payments`, 'get',
            { per_page: 1000 },
            ({ data }) => {
              commit('SET_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
  },
  getters: {
    fetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    options(state) {
      return state.options;
    },
  },
};
