export default {
  not_found: {
    title: 'Form Not Found',
    subtitle: 'Contact your agent please',
  },
  new_form: {
    title: 'New Student Form',
    subtitle: 'Please complete all the form',
  },
  first_step: {
    title: 'Student',
    fields: {
      picture: 'Profile Picture',
      picture_info: `The image must be JPG or PNG<br>
        The dimensions must be 300x300<br>
        The size cannot be higher than 5mb`,
      name_info: 'Please add your Full Passport/ID name',
      name: 'Forenames and Middle Names (if you have)',
      surname: 'Surnames',
      nationality: 'Nationality (Same as passport)',
      gender: 'Gender',
      gender_options: [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' },
      ],
      birth_date: 'Birth Date',
      email: 'Email',
      phone: 'Phone',
      emergency_contact_name: 'Emergency Contact Full Name (Relation)',
      emergency_contact: 'Emergency Contact (Email / Phone)',
      flight: 'Flight',
      flight_status: 'Flight Ticket',
      flight_status_options: [
        { id: 'living_in_ireland', name: 'I am already living in Spain' },
        { id: 'no_ticket_yet', name: 'I do not have my flight ticket yet' },
        { id: 'have_ticket', name: 'I have a flight ticket' },
      ],
      flight_code: 'Flight Code',
      flight_departure_date: 'Departure Date from your country',
      flight_arrival_date: 'Arrival Date to Spain',
      flight_airline: 'Airline',
      flight_voucher_button: 'Upload Flight Voucher',
      flight_voucher_button_info: 'File must be JPG/PNG/PDF max 5MB',
      file_to_upload: 'File to Upload:',
      flight_voucher_download: 'Download Uploaded File',
      entry_visa_switch: 'I have Entry Visa',
      entry_visa_button: 'Upload Entry Visa Certificate',
      entry_visa_button_info: 'File must be JPG/PNG/PDF max 5MB',
      entry_visa_download: 'Download Uploaded File',
      irp_switch: 'I do not have a NIE yet',
      irp: 'NIE',
      irp_reg_number: 'NIE Number',
      irp_issue_date: 'Issue Date',
      irp_exp_date: 'Expiration Date',
      irp_stamp: 'Stamp',
      irp_stamp_options: [
        { id: 'STAMP 1/1G', name: '1st Visa' },
        { id: 'STAMP 2', name: '2nd Visa' },
        { id: 'STAMP 4', name: '3rd Visa' },
        { id: 'EUROPEAN CITIZENSHIP', name: 'EU Passport' },
        { id: 'OTHER', name: 'No Visa' },
      ],
      passport_switch: 'I do not have a Passport yet',
      passport: 'Passport',
      passport_number: 'Passport Number',
      passport_country: 'Passport Issued Country',
      passport_issued_date: 'Passport Issued Date',
      passport_exp_date: 'Passport Expiration Date',
      passport_button: 'Upload Passport Photo',
      passport_button_info: 'File must be JPG/PNG/PDF max 5MB',
      passport_download: 'Download Passport Photo',
      address: 'Address',
      address_type: 'Address Type',
      address_types: [
        { id: 'Local', name: 'Local Spanish Address' },
        { id: 'Home', name: 'Home Country Address' },
      ],
      address_help_text: 'Home Country Address (If you are living in your country) - Local Spanish Address (if you are currently living in Spain or if you have a Spanish address)',
      address_city: 'City',
      address_county: 'Province',
      address_country: 'Country',
      address_zip_code: 'Post Code',

    },
    errors: {
      passport: {
        file: 'Upload Passsport file to continue',
        number: 'Passport Number is required',
        country: 'Passport Issued Country is required',
        issue_date: 'Passport Issue Date is required',
        expiration_date: 'Passport Expiration Date is required',
      },
    },
  },
  exam_step: {
    title: 'Exam',
  },
  second_step: {
    title: 'Payments',
    pay_before: 'Your payments must be paid before',
    paid: 'PAID',
    due: 'DUE',
    new_payment: 'New Payment',
    expected_date: 'Expected Date',
    payment: 'Payment',
    payment_date: 'Payment Date',
    amount: 'Amount',
    method: 'Method',
    status: 'Status',
    select_date: 'Select date',
    select_method: 'Select a Payment method',
    refunded: 'Refunded',
    statuses: {
      'Waiting for Approval': 'Waiting for Approval',
      Overdue: 'Overdue',
      Void: 'Void',
      Unpaid: 'Unpaid',
      Rejected: 'Rejected',
      Paid: 'Paid',
    },
    proof_button: 'Upload Proof of Payment',
    processing_payment: 'We are processing your payment',
    delete: 'Delete',
    total_package: 'Total Package',
    total_payments: 'Total Payments',
    payment_expected_date: 'Payment Expected Date',
    payment_done_date: 'Payment Done Date',
    errors: {
      not_enough_payments: 'The total of payments is not enough to complete the package price. Please add a new Payment',
      too_much_payments: 'The total of payments is greater than the package price. Please check the amount of your payments and delete some if needed.',
      complete_columns: 'Complete all the columns for each payment please',
      invalid_dates: 'Your Payments must be paid before',
      expected_payments: 'Expected Payments',
    },
  },
  file_modal: {
    title: 'Upload Form',
    file_info: `<li>
        Files size cannot be greater than 5MB. You can reduce the file size 
        <a
          href="https://www.adobe.com/ie/acrobat/online/compress-pdf.html"
          target="_blank"
        >HERE</a>
      </li>
      <li>
        Files extension must be <b>.pdf / .jpg / .jpeg / .png</b>
      </li>`,
    fields: {
      title: 'File Title',
      choose_file: 'Choose File',
      button: 'Upload',
      default_title: 'Proof of Payment uploaded by Student - Scheduled Payment #',
    },
    success_msg: 'File uploaded successfully!',
  },
  success_msg: {
    fully_answered: 'Thank you for your time, we will review your responses and confirm your registration.',
    incomplete_fields: `Thank you for your time. <br> 
      We noticed that some fields are incomplete, you can complete 
      them using the "Student Application Form" email's link.<br>
      <h5>Incomplete Fields</h5> $FIELDS$`,
    form_saved: 'Form saved successfully',
  },
  errors: {
    complete_fields: 'Please, complete all the required fields before.',
  },
};
