/* eslint-disable camelcase */
import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
  },
  mutations: {
    SET_OPTIONS(state, { data, with_agent }) {
      state.options = data.map((x) => {
        const opts = {
          id: x.student_id,
          student_id: x.student_id,
          name: `${x.student_number} | ${x.full_name} ${with_agent ? `| Agent: ${x.programmes[0].agent.name}` : ''}`,
          student_number: x.student_number,
          full_name: x.full_name,
        };

        if (with_agent) {
          opts.programmes = x.programmes
            .map((z) => ({ id: z.programme_id, name: `"${z.status}" - Start Date: ${moment(z.start_date).format('DD-MM-YYYY')}` }));
        }

        return opts;
      });
    },
  },
  actions: {
    getOptions({ commit }, { search, with_agent, with_app }) {
      commit('SHOW_LOADER', null, { root: true });
      return new Promise(() => {
        utils.methods.request('students', 'get',
          { search, with_agent, with_app },
          ({ data }) => {
            commit('SET_OPTIONS', { data, with_agent });
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
          });
      });
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
  },
};
