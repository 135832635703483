/* eslint-disable camelcase */
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, { data }) {
      state.options = data.map((x) => {
        const aux = x;
        aux.id = x.classroom_id;
        aux.name = `${x.classroom_code} | ${x.classroom_name.name}`;
        return aux;
      });
    },
  },
  actions: {
    getOptions({ commit }, {
      teacher_id, class_schedule_id, course_id, level_id, cb,
    }) {
      return new Promise(() => {
        commit('SHOW_LOADER', null, { root: true });
        utils.methods.requestAsync('classrooms', 'get', {
          per_page: 1000,
          is_active: 1,
          sort_by: 'classroom_code',
          sort_dir: 'asc',
          teacher_id,
          class_schedule_id,
          level_id,
          course_id,
        })
          .then(({ data }) => {
            commit('SET_OPTIONS', data);
          })
          .finally(() => {
            commit('HIDE_LOADER', null, { root: true });
            if (cb) cb();
          });
      });
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
  },
};
