<template>
  <div
    ref="sidebar"
    class="sidebar"
    :data-color="$schoolSettings.menu_active_color"
    :data-image="backgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <a class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="logo">
        </div>
      </a>
      <a class="simple-text logo-normal">
        <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
        <template v-else>{{ title }}</template>
      </a>
      <div class="navbar-minimize">
        <md-button
          id="minimizeSidebar"
          class="md-round md-just-icon md-transparent"
          @click="minimizeSidebar"
        >
          <i
            class="material-icons text_align-center visible-on-sidebar-regular"
          >more_vert</i>
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
          >view_list</i>
        </md-button>
      </div>
    </div>
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper"
    >
      <slot />
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, i) in sidebarLinks"
            :key="link.name + i"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, j) in link.children"
              :key="subLink.name + j"
              asdasd
              :link="subLink"
            />
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  props: {
    title: {
      type: String,
      default: 'KMPUS',
    },
    rtlTitle: {
      type: String,
      default: 'توقيت الإبداعية',
    },
    backgroundImage: {
      type: String,
      default: '/img/sidebar-2.jpg',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        const acceptedValues = ['', 'black', 'white', 'red'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: '/img/kmpus_logo.png',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        '--sidebarBgColor': this.hexToRGB(this.$schoolSettings.menu_bg_color, 0.5),
        '--sidebarColor': this.hexToRGB(this.$schoolSettings.menu_text_color),
      };
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    hexToRGB(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }
      return `rgb(${r}, ${g}, ${b})`;
    },
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
