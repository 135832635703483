/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import langInstance from '../lang/index';

export default {
  methods: {
    $lang(key, fromLangRoot = false) {
      return key.split('.').reduce(
        (o, i) => {
          if (o) return o[i];
        },
        fromLangRoot
          ? langInstance[this.$schoolSettings.lang]
          : langInstance[this.$schoolSettings.lang][this.$route.name],
      );
    },
  },
};
