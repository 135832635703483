import Swal from 'sweetalert2';
import Vue from 'vue';

export default {
  methods: {
    fireModal({
      title,
      html,
      input = null,
      inputPlaceholder = null,
      inputOptions = null,
      inputValue = null,
      customClass,
    }) {
      if (input) {
        if (input === 'select') {
          return Swal.fire({
            title,
            html,
            input,
            inputPlaceholder,
            inputOptions,
            showCloseButton: true,
            confirmButtonText: 'OK',
            customClass,
          });
        }
        return Swal.fire({
          title,
          html,
          input,
          inputValue,
          inputPlaceholder,
          showCloseButton: true,
          confirmButtonText: 'OK',
          customClass,
        });
      }
      return Swal.fire({
        title,
        html,
        showCloseButton: true,
        confirmButtonText: 'OK',
        customClass,
      });
    },
    fireConfirm(title, text, cancelButtonText = 'Cancel') {
      return new Promise((resolve, reject) => {
        Swal.fire({
          icon: 'warning',
          title,
          text,
          type: 'danger',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success',
          cancelButtonClass: 'md-button md-danger',
          cancelButtonText,
          buttonsStyling: false,
        }).then((res) => {
          if (res.value) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    fireSuccess(msg) {
      Vue.prototype.$noty.success(msg);
    },
    fireWarning(msg) {
      Vue.prototype.$noty.warning(msg);
    },
    fireError(msg) {
      Vue.prototype.$noty.error(msg);
    },
  },
};
