export default {
  methods: {
    openLink(route, new_tab) {
      if (new_tab) {
        const routeData = this.$router.resolve(route);
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(route);
      }
    },
  },
};
