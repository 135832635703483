export default {
  computed: {
    $schoolSettings() {
      return this.$store.getters['school_settings/settings'];
    },
    $publicProfileBg() {
      const { public_profile_bg_1, public_profile_bg_2 } = this.$store.getters['school_settings/settings'];

      return `linear-gradient(90deg, ${public_profile_bg_1} 0%, ${public_profile_bg_2} 100%)`;
    },
  },
};
