import moment from 'moment';
import utils from '../../core/serviceUtils';
import API from '../../core/apiRoutes';

const LOCAL_STORAGE_KEYS = {
  SCHOOL_SETTINGS: 'school_settings',
  USER: 'user',
};

const ONE_HOUR_IN_MILLISECONDS = 3600000;

const setPageTitle = (title) => {
  document.title = `KMPUS - ${title}`;
};
const getSettingsFromMemory = () => {
  const inMemorySettings = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.SCHOOL_SETTINGS),
  );

  if (!inMemorySettings) return null;

  const isExpired = moment().valueOf() > inMemorySettings.fetch_at + ONE_HOUR_IN_MILLISECONDS;

  if (isExpired) return null;

  return inMemorySettings;
};
const saveSettingsToMemory = (value) => {
  const publicAttrs = [
    'school_setting_id',
    'lang',
    'menu_bg_color',
    'menu_text_color',
    'menu_active_color',
    'campus_name',
    'date_format',
    'public_profile_bg_1',
    'public_profile_bg_2',
    'college_logo',
  ];

  const publicSettings = {};
  for (const key in value) {
    if (publicAttrs.includes(key)) {
      publicSettings[key] = value[key];
    }
  }

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.SCHOOL_SETTINGS,
    JSON.stringify(publicSettings),
  );

  setPageTitle(value.campus_name);
};
const isLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

  return !!user;
};

export default {
  namespaced: true,
  state: {
    settings: {},
  },
  mutations: {
    SET_SETTINGS(state, value) {
      saveSettingsToMemory(value);

      state.settings = value;
    },
  },
  actions: {
    getSchoolSettings({ commit }, { cb, force = false }) {
      const inMemorySettings = getSettingsFromMemory();
      const notLogged = !isLoggedIn();

      if (inMemorySettings && !force) {
        commit('SET_SETTINGS', inMemorySettings);
        if (cb) cb();
        return;
      }

      try {
        commit('SHOW_LOADER', null, { root: true });
        const url = notLogged ? `${API.SCHOOL_SETTINGS}/public` : API.SCHOOL_SETTINGS;

        utils.methods.request(url, 'get', {},
          ({ data }) => {
            const { school_setting_id, ...args } = data;

            commit('SET_SETTINGS', {
              fetch_at: moment().valueOf(),
              ...args,
            });
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
            if (cb) cb();
          }, notLogged);

        if (cb) cb();
      } catch (error) {
        commit('HIDE_LOADER', null, { root: true });
      }
    },
    updateSchoolSettings({ commit, dispatch }, { form, cb }) {
      commit('SHOW_LOADER', null, { root: true });

      utils.methods.request(API.SCHOOL_SETTINGS, 'put', form,
        () => {
          dispatch('getSchoolSettings', { force: true });
          if (cb) cb();
        }, () => {
          commit('HIDE_LOADER', null, { root: true });
        });
    },
  },
  getters: {
    settings: (state) => state.settings,
  },
};
