import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
  },
  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data.map((x) => {
        const aux = x;
        aux.id = x.course_id;
        aux.name = x.title;
        return aux;
      });
    },
  },
  actions: {
    getOptions({ commit }, { cb, params }) {
      commit('SHOW_LOADER', null, { root: true });
      return new Promise(() => {
        utils.methods.request('courses', 'get',
          params,
          ({ data }) => {
            commit('SET_OPTIONS', data);
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
            if (cb) cb();
          });
      });
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
  },
};
