/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-async-promise-executor */
import moment from 'moment';
import axios from 'axios';
import alerts from '../mixins/alerts';

const Method = Object.freeze({
  post: 'post',
  get: 'get',
  put: 'put',
  delete: 'delete',
});

export default {
  methods: {
    validateSession() {
      let isValid = true;

      if (!localStorage.token) {
        isValid = false;

        this.cleanSession();
        this.$router.push({ name: 'Login' }).catch(() => {});
      }

      return isValid;
    },
    async validateVersion() {
      const version = localStorage.getItem('kmpus_version') || null;

      await axios
        .get(process.env.VUE_APP_SERVER + this.$API.VERSION)
        .then(({ data }) => {
          if (version !== data.version) {
            localStorage.setItem('kmpus_version', data.version);
            window.location.reload(true);
          }
        })
        .catch(() => {
          window.location.reload(true);
        });
    },

    onError(e) {
      if (e.errors) {
        Object.values(e.errors).forEach((x) => {
          x.forEach((z) => this.fireError(z));
        });
      } else if (e.message) {
        // Logout if not authorized
        if ([401, 403].includes(e.code)) {
          this.cleanSession();
          this.$router.push({ name: 'Login' }).catch(() => {});
        }

        // Show error
        if (e.message) this.fireError(e.message);
      } else {
        this.fireError('Unexpected error, please try again in a few minutes');
        console.error(e);
      }
    },
    async guestRequest(url, method, params, callback, finalCallback) {
      await this.request(
        url,
        method,
        params,
        callback,
        finalCallback,
        false,
        true,
      );
    },
    async guestRequestAsync(url, method, params) {
      await this.requestAsync(
        url,
        method,
        params,
        true,
      );
    },
    async request(
      url,
      method = Method.get,
      params = null,
      callback,
      finalCallback = null,
      fromLogin = false,
      isGuest = false,
    ) {
      try {
        if (!fromLogin && !isGuest) {
          const isValid = this.validateSession();

          if (!isValid) {
            return;
          }
        }

        let cleanParams = null;
        if (params) {
          cleanParams = !(params instanceof FormData)
            ? this.paramsSanitization(params)
            : params;
        }

        const headers = { Authorization: `Bearer ${localStorage.token}` };
        const fullUrl = process.env.VUE_APP_SERVER + url;

        if (this.$store) this.$store.commit('SHOW_LOADER');

        switch (method) {
          case 'get':
            axios
              .get(fullUrl, { headers, params: cleanParams })
              .then(({ data }) => callback(data))
              .catch((err) => {
                console.log(err, err.response);
                this.onError(err.response.data);
              })
              .finally(() => {
                // always executed
                if (this.$store) this.$store.commit('HIDE_LOADER');
                if (finalCallback) finalCallback();
              });
            break;
          case 'post':
            axios
              .post(fullUrl, cleanParams, { headers })
              .then(({ data: { data } }) => callback(data))
              .catch((err) => {
                console.log(err, err.response);
                this.onError(err.response.data);
              })
              .finally(() => {
                // always executed
                if (this.$store) this.$store.commit('HIDE_LOADER');
                if (finalCallback) finalCallback();
              });
            break;
          case 'put':
            axios
              .put(fullUrl, cleanParams, { headers })
              .then(({ data: { data } }) => callback(data))
              .catch((err) => {
                console.log(err, err.response);
                this.onError(err.response.data);
              })
              .finally(() => {
                // always executed
                if (this.$store) this.$store.commit('HIDE_LOADER');
                if (finalCallback) finalCallback();
              });
            break;
          case 'delete':
            axios
              .delete(fullUrl, { headers, params: cleanParams })
              .then(({ data: { data } }) => callback(data))
              .catch((err) => {
                console.log(err, err.response);
                this.onError(err.response.data);
              })
              .finally(() => {
                // always executed
                if (this.$store) this.$store.commit('HIDE_LOADER');
                if (finalCallback) finalCallback();
              });
            break;

          case 'download':
            axios
              .get(fullUrl, {
                headers,
                responseType: 'blob',
                params: cleanParams,
              })
              .then((response) => callback(response))
              .catch(async (err) => {
                const error = await err.response.data.text();
                console.log(error);

                this.onError(JSON.parse(error));
              })
              .finally(() => {
                // always executed
                if (this.$store) this.$store.commit('HIDE_LOADER');
                if (finalCallback) finalCallback();
              });
            break;

          default:
            break;
        }
      } catch (error) {
        this.fireError(error.message);
      }
    },
    requestAsync(url, method = Method.get, params = null, is_guest = false) {
      return new Promise((resolve, reject) => {
        try {
          let cleanParams = null;
          if (params) {
            cleanParams = !(params instanceof FormData)
              ? this.paramsSanitization(params)
              : params;
          }

          let headers = {};
          if (!is_guest) {
            headers = { Authorization: `Bearer ${localStorage.token}` };
          }
          const fullUrl = process.env.VUE_APP_SERVER + url;

          if (this.$store) this.$store.commit('SHOW_LOADER');

          switch (method) {
            case 'get':
              axios
                .get(fullUrl, { headers, params: cleanParams })
                .then(({ data }) => resolve(data))
                .catch((err) => {
                  console.log(err, err.response);
                  this.onError(err.response.data);
                  reject();
                })
                .finally(() => {
                  if (this.$store) this.$store.commit('HIDE_LOADER');
                });
              break;
            case 'post':
              axios
                .post(fullUrl, cleanParams, { headers })
                .then(({ data: { data } }) => resolve(data))
                .catch((err) => {
                  console.log(err, err.response);
                  this.onError(err.response.data);
                  reject();
                })
                .finally(() => {
                  if (this.$store) this.$store.commit('HIDE_LOADER');
                });
              break;
            case 'put':
              axios
                .put(fullUrl, cleanParams, { headers })
                .then(({ data: { data } }) => resolve(data))
                .catch((err) => {
                  console.log(err, err.response);
                  this.onError(err.response.data);
                  reject();
                })
                .finally(() => {
                  if (this.$store) this.$store.commit('HIDE_LOADER');
                });
              break;
            case 'delete':
              axios
                .delete(fullUrl, { headers, params: cleanParams })
                .then(({ data: { data } }) => resolve(data))
                .catch((err) => {
                  console.log(err, err.response);
                  this.onError(err.response.data);
                  reject();
                })
                .finally(() => {
                  if (this.$store) this.$store.commit('HIDE_LOADER');
                });
              break;

            default:
              break;
          }
        } catch (error) {
          this.fireError(error.message);
        }
      });
    },

    /**
     * Method to sanitize the params in
     * @param {Object} obj any object to server request
     */
    paramsSanitization(obj) {
      const dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      const aux = JSON.parse(JSON.stringify(obj));

      for (const k of Object.keys(obj)) {
        if (obj[k] instanceof Blob) {
          aux[k] = obj[k];
        } else if (typeof obj[k] === 'string' && !aux[k].match(dateReg)) {
          // remove blank spaces in the strings
          aux[k] = obj[k].trim();
        } else if (typeof obj[k] === 'string' && aux[k].match(dateReg)) {
          // convert to string
          aux[k] = moment(aux[k]).format('YYYY-MM-DD');
        } else if (obj[k] instanceof moment) {
          // convert dates object to string in format YYYY-MM-DD
          aux[k] = obj[k].format('YYYY-MM-DD');
        } else if (obj[k] instanceof Date) {
          aux[k] = moment(aux[k]).format('YYYY-MM-DD');
        } else if (aux[k] && typeof aux[k] === 'object') {
          // recursivity to check objects inside of the object
          aux[k] = this.paramsSanitization(aux[k]);
        }

        // Mutate Paginable Params
        switch (k) {
          case 'sortBy':
            aux.sort_by = aux[k];
            delete aux[k];
            break;
          case 'sortDir':
            aux.sort_dir = aux[k];
            delete aux[k];
            break;
          default:
            break;
        }
      }

      return aux;
    },
    async formDataFromObj(obj, withNull = true) {
      return new Promise(async (resolve) => {
        const fd = new FormData();
        const data = await this.paramsSanitization(obj);

        for (const i in data) {
          if (withNull) {
            if (typeof data[i] === 'object' && !(data[i] instanceof File)) {
              if (Array.isArray(data[i])) {
                if (data[i].length) {
                  fd.append(i, JSON.stringify(data[i]));
                }
              } else {
                fd.append(i, JSON.stringify(data[i]));
              }
            } else if (data[i] === 'null') {
              fd.append(i, null);
            } else {
              fd.append(i, data[i]);
            }
          } else if (data[i]) {
            if (typeof data[i] === 'object' && !(data[i] instanceof File)) {
              if (Array.isArray(data[i])) {
                if (data[i].length) {
                  fd.append(i, JSON.stringify(data[i]));
                }
              } else {
                fd.append(i, JSON.stringify(data[i]));
              }
            } else if (data[i] && data[i] !== 'null') {
              fd.append(i, data[i]);
            }
          }
        }

        resolve(fd);
      });
    },
    setSession(data) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.userRes));
      localStorage.setItem('parameters', JSON.stringify(data.parameters));
    },
    setAdminSession() {
      const admin_token = localStorage.getItem('token');
      const admin_user = localStorage.getItem('user');

      localStorage.setItem('admin_token', admin_token);
      localStorage.setItem('admin_user', admin_user);
    },
    logoutToAdmin() {
      this.$store.commit('SHOW_LOADER');

      const admin_token = localStorage.getItem('admin_token');
      const admin_user = localStorage.getItem('admin_user');

      localStorage.removeItem('admin_token');
      localStorage.removeItem('admin_user');

      localStorage.setItem('token', admin_token);
      localStorage.setItem('user', admin_user);

      this.$store.commit('HIDE_LOADER');

      this.$router.push('/');
    },
    cleanSession() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('parameters');
      localStorage.removeItem('school_settings');
    },
    dateFormat(date, reverse = false) {
      if (!date) return null;

      if (reverse) {
        return moment(date, this.$store.getters['school_settings/settings'].date_format).format(
          'YYYY-MM-DD',
        );
      }

      return moment(date).format(this.$store.getters['school_settings/settings'].date_format);
    },
    dateTimeFormat(date, withSeconds = false) {
      if (!date) return null;
      return moment(date).format(`DD-MM-YYYY HH:mm${withSeconds ? ':ss' : ''}`);
    },
    moneyFormat(price) {
      if (typeof price === 'number') {
        return `€${price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
      }
      return `€${parseFloat(price)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
    },
    fireError(val) {
      alerts.methods.fireError(val);
    },
    parseVisaName(name) {
      return (
        this.$store.getters['visaTypes/options'].find((x) => x.id === name)
          ?.name || '-'
      );
    },
    getFileUrl(file_id) {
      return new Promise((resolve) => {
        this.requestAsync(`files/${file_id}`)
          .then(({ data }) => {
            resolve(data);
          });
      });
    },
    openFileUrl(file_id) {
      this.requestAsync(`files/${file_id}`)
        .then(({ data }) => {
          window.open(data, '_blank');
        });
    },
    getLetterUrl(pdf_path) {
      const token = pdf_path.split('/').pop().split('.').shift();

      this.requestAsync(`letters/${token}`)
        .then(({ data }) => {
          window.open(data, '_blank');
        });
    },
  },
  data: () => ({
    avatar: '/img/default-avatar.png',
  }),
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'));
    },
    userEndpoints() {
      return new Set(
        this.user.roles.flatMap((x) => x.endpoints.flatMap((y) => y.endpoint.name)).flat(),
      );
    },
    userIsAgent() {
      return this.user.is_agent;
    },
    userIsAgentsManager() {
      return this.user.roles.filter((x) => x.role_id === 1).length > 0
      || this.agentGroupsManagerIds.length > 0;
    },
    agentGroupsManagerIds() {
      if (this.userIsAgent) {
        return this.user.agent_groups
          .filter((x) => x.is_boss)
          .map((x) => x.agent_group_id);
      }

      return [];
    },
    userIsFullAdmin() {
      return this.user.roles.map((x) => x.role_id).includes(1);
    },
    system_parameters() {
      return JSON.parse(localStorage.getItem('parameters'));
    },
    userCanDeactivateLettersLink() {
      if (!this.user) return false;
      return this.user.roles.some(
        ({ role_id, permissions }) => role_id === 1
          || permissions.some((permission) => permission.permission_id === 3),
      );
    },
    userCanOpenSettings() {
      if (this.userIsFullAdmin) return true;

      const requiredEndpoints = [
        'school_settings.show',
        'school_settings.create',
      ];
      let valid = true;

      requiredEndpoints.forEach((z) => {
        if (!this.userEndpoints.has(z)) valid = false;
      });

      return valid;
    },
    signRequestActive() {
      if (!this.system_parameters) return false;
      const srParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 1,
      );
      if (!srParam.length) return false;
      return !!srParam[0].value;
    },
    AutoInvoiceActive() {
      if (!this.system_parameters) return false;
      const srParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 2,
      );
      if (!srParam.length) return false;
      return !!srParam[0].value;
    },
    AutoReceiptActive() {
      if (!this.system_parameters) return false;
      const srParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 3,
      );
      if (!srParam.length) return false;
      return !!srParam[0].value;
    },
    onlinePaymentsActive() {
      if (!this.system_parameters) return false;
      const opParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 4,
      );
      if (!opParam.length) return false;
      return !!opParam[0].value;
    },
    autoPlacementTestActive() {
      if (!this.system_parameters) return false;
      const opParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 7,
      );
      if (!opParam.length) return false;
      return !!opParam[0].value;
    },
    warningSystemActive() {
      if (!this.system_parameters) return false;
      const opParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 17,
      );
      if (!opParam.length) return false;
      return !!opParam[0].value;
    },
    exitReportActive() {
      if (!this.system_parameters) return false;
      const opParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 10,
      );
      if (!opParam.length) return false;
      return !!opParam[0].value;
    },
    chatActive() {
      if (!this.system_parameters) return false;
      const srParam = this.system_parameters.filter(
        (x) => x.system_parameter_id === 8,
      );
      if (!srParam.length) return false;
      return !!srParam[0].value;
    },
  },
};
