import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
  },
  actions: {
    getOptions({ commit }, { cb, filters }) {
      return new Promise(() => {
        commit('SHOW_LOADER', null, { root: true });
        utils.methods.request('agents', 'get',
          filters,
          ({ data }) => {
            cb(data.map((x) => {
              const agent = x;
              agent.name = x.name;
              agent.id = x.user_id;
              return agent;
            }));
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
          });
      });
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
  },
};
