import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
    commissionable_options: [],
    commissionable_fetch_at: null,
    accommodation_options: [],
    accommodation_fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, { data }) {
      state.options = data.map((x) => {
        const item = x;
        item.id = x.package_item_id;
        item.price = x.default_price;
        item.key = Math.random() * Date.now();
        return item;
      });
    },
    SET_FETCH_AT(state) {
      state.fetch_at = moment().valueOf();
    },
    SET_COMMISSIONABLE_OPTIONS(state, { data }) {
      state.commissionable_options = data.map((x) => {
        const item = x;
        item.id = x.package_item_id;
        item.price = x.default_price;
        item.key = Math.random() * Date.now();
        return item;
      });
    },
    SET_COMMISSIONABLE_FETCH_AT(state) {
      state.commissionable_fetch_at = moment().valueOf();
    },
    SET_ACCOMMODATION_TYPES_OPTIONS(state, data) {
      state.accommodation_options = data.map((x) => {
        const item = x;
        item.id = x.package_item_id;
        item.key = Math.random() * Date.now();
        return item;
      });
    },
    SET_ACCOMMODATION_FETCH_AT(state) {
      state.accommodation_fetch_at = moment().valueOf();
    },
  },
  actions: {
    getOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.fetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('package_items', 'get',
            { per_page: 1000 },
            ({ data }) => {
              commit('SET_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
    getCommissionableOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.commissionableFetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('package_items', 'get',
            { per_page: 1000, commissionable: true },
            ({ data }) => {
              commit('SET_COMMISSIONABLE_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_COMMISSIONABLE_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
    getAccommodationTypesOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.commissionableFetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('accommodation_types', 'get', null,
            ({ data }) => {
              commit('SET_ACCOMMODATION_TYPES_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_ACCOMMODATION_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
  },
  getters: {
    fetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    commissionableFetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    options(state) {
      return state.options;
    },
    required(state) {
      return state.options.filter((x) => x.required);
    },
    optional(state) {
      return state.options.filter((x) => !x.required);
    },
    commissionableOptions(state) {
      return state.commissionable_options;
    },
    accommodationOptions(state) {
      return state.accommodation_options;
    },
  },
};
