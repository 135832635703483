import moment from 'moment';
import utils from '../../core/serviceUtils';

const dateFormat = (date, format) => (!date ? 'No Start Date' : moment(date).format(format));
const order = {
  Enrolled: 1,
  'Pre-Enrolled': 2,
  'On-Hold': 3,
  'Partially Paid': 4,
  'Fully Paid': 5,
  Unpaid: 6,
  'Waiting for Approval': 7,
  Inactive: 8,
  Holiday: 9,
  Expelled: 10,
  Completed: 11,
  Cancelled: 12,
  Finished: 13,
};

export default {
  namespaced: true,
  state: {
    options: [],
  },
  mutations: {
    SET_OPTIONS(state, { data, format }) {
      state.options = data
        .sort((a, b) => {
          if (order[a.status] > order[b.status]) return 1;
          return -1;
        })
        .map((x) => ({
          ...x,
          id: x.programme_id,
          name: `${dateFormat(x.start_date, format)} | ${x.course.title} "${x.status}"`,
        }));
    },
  },
  actions: {
    getOptions({ commit, rootGetters: getters }, { id }) {
      commit('SHOW_LOADER', null, { root: true });
      return new Promise(() => {
        utils.methods.request(`students/${id}/programmes`, 'get',
          null,
          ({ data }) => {
            commit('SET_OPTIONS', { data: data.data, format: getters['school_settings/settings'].date_format });
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
          });
      });
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
  },
};
