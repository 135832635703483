// =========================================================
// * Vue Material Dashboard PRO - v1.3.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission
// notice shall be included in all copies or
// substantial portions of the Software.

import Vue from 'vue';
import VueRouter from 'vue-router';
import Chartist from 'chartist';
import VueModal from '@farena/vue-modal';
import '@farena/vue-modal/dist/vue-modal.css';
import VCalendar from 'v-calendar';
import alerts from '@/mixins/alerts';
import schoolSettings from '@/mixins/schoolSettings';
import './utils/paginable';
import './utils/utilsFunctions';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import Vue2Filters from 'vue2-filters';
import IdleVue from 'idle-vue';
import Clipboard from 'v-clipboard';
import VueNoty from 'vuejs-noty';
import serviceUtils from './core/serviceUtils';
import DashboardPlugin from './material-dashboard';
import 'vuejs-noty/dist/vuejs-noty.css';
import lang from './mixins/lang';
import openLink from './mixins/openLink';

// Bootstrap utilities
import '@/assets/scss/bootstrap4/scss/b4utilities.scss';

import App from './App.vue';

// router setup
import routes from './routes/routes';

// api routes
import API from './core/apiRoutes';

// Setup VUEX
import store from './store';

// plugin setup
Vue.use(VueTrumbowyg);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueModal);
Vue.use(VCalendar);
Vue.use(Clipboard);
Vue.use(VueNoty);

// Global mixins
Vue.mixin(schoolSettings);
Vue.mixin(alerts);
Vue.mixin(serviceUtils);
Vue.mixin(lang);
Vue.mixin(openLink);

// Global request
Vue.use(VueAxios, axios);

// Filters
Vue.use(Vue2Filters);

// Inactivity
const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1000 * 60 * 60,
});

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  linkExactActiveClass: 'nav-item active',
});

// global library setup
Vue.prototype.$Chartist = Chartist;
Vue.prototype.$API = API;
Vue.config.silent = true;
Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    if (![
      "Cannot read property 'badInput' of undefined",
      "Cannot read properties of undefined (reading 'badInput')",
    ].includes(err.message)) {
      console.error(err);
    }
  }
};

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
  onIdle() {
    if (this.$route.name !== 'StudentForm' && this.validateSession()) {
      this.$router.push({ name: 'Lock' }).catch(() => { });
    }
  },
});
