import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, { data }) {
      state.options = data.map((x) => {
        const aux = x;
        aux.id = x.letter_layout_id;
        aux.name = x.title;
        return aux;
      });
    },
    SET_FETCH_AT(state) {
      state.fetch_at = moment().valueOf();
    },
  },
  actions: {
    getOptions({ commit, getters }, { cb, force = false }) {
      return new Promise(() => {
        if (force || getters.fetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('letter_layouts', 'get',
            { per_page: 1000 },
            ({ data }) => {
              commit('SET_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
  },
  getters: {
    fetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    options(state) {
      return state.options;
    },
  },
};
