export default {
  state: {
    loading: false,
  },
  mutations: {
    SHOW_LOADER(state) {
      state.loading = true;
    },
    HIDE_LOADER(state) {
      state.loading = false;
    },
  },
  getters: {
    loading: (state) => state.loading,
  },
};
