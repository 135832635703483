import Vue from 'vue';
import Vuex from 'vuex';
import agents from './modules/agents';
import agentGroups from './modules/agent_groups';
import authorities from './modules/authorities';
import classrooms from './modules/classrooms';
import courses from './modules/courses';
import departments from './modules/departments';
import examQuestions from './modules/exam_questions';
import exams from './modules/exams';
import fileTypes from './modules/file_types';
import letterTemplates from './modules/letter_templates';
import levels from './modules/levels';
import mktSources from './modules/mkt_sources';
import moneyflowCategories from './modules/moneyflow_categories';
import packageItemCategories from './modules/package_item_categories';
import packageItems from './modules/package_items';
import paymentMethods from './modules/payment_methods';
import signRequest from './modules/sign_request';
import studentProgrammes from './modules/student_programmes';
import teachers from './modules/teachers';
import countries from './modules/countries';
import students from './modules/students';
import packagePayments from './modules/package_payments';
import agencies from './modules/agencies';
import quotationTemplates from './modules/quotation_templates';
import segments from './modules/segments';
import classroomNames from './modules/classroom_names';
import requestTypes from './modules/request_types';
import requestTypeCategories from './modules/request_type_categories';
import news from './modules/news';
import calendarCategories from './modules/calendar_categories';
import activities from './modules/activities';
import visaTypes from './modules/visa_types';
import staff from './modules/staff';
import per_question_categories from './modules/per_question_categories';
import loader from './modules/loader';
import periods from './modules/periods';
import buildings from './modules/buildings';
import school_settings from './modules/school_settings';
import examTypes from './modules/exam_types';
import letterLayouts from './modules/letter_layouts';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loader,
    agents,
    agentGroups,
    authorities,
    classrooms,
    classroomNames,
    countries,
    courses,
    departments,
    examQuestions,
    exams,
    fileTypes,
    letterTemplates,
    levels,
    mktSources,
    moneyflowCategories,
    packageItemCategories,
    packageItems,
    agencies,
    paymentMethods,
    signRequest,
    students,
    studentProgrammes,
    teachers,
    packagePayments,
    quotationTemplates,
    segments,
    requestTypes,
    requestTypeCategories,
    news,
    calendarCategories,
    activities,
    visaTypes,
    staff,
    per_question_categories,
    periods,
    buildings,
    school_settings,
    examTypes,
    letterLayouts,
  },
});
