import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data.map((x) => ({
        id: x.file_type_id,
        name: x.name,
      }));
    },
    SET_FETCH_AT(state) {
      state.fetch_at = moment().valueOf();
    },
  },
  actions: {
    getOptions({ commit }, { cb }) {
      return new Promise(() => {
        commit('SHOW_LOADER', null, { root: true });
        utils.methods.request('file_types', 'get',
          null,
          ({ data }) => {
            commit('SET_OPTIONS', data);
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
            if (cb) cb();
          });
      });
    },
    getAdminOptions({ commit }, { cb }) {
      return new Promise(() => {
        commit('SHOW_LOADER', null, { root: true });
        utils.methods.request('file_types/admin/types', 'get',
          null,
          ({ data }) => {
            commit('SET_OPTIONS', data);
          }, () => {
            commit('HIDE_LOADER', null, { root: true });
            if (cb) cb();
          });
      });
    },
  },
  getters: {
    fetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    options(state) {
      return state.options;
    },
  },
};
