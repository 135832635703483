import StudentFormEN from './en/StudentForm';
import StudentFormES from './es/StudentForm';

export default {
  en: {
    StudentForm: StudentFormEN,
  },
  es: {
    StudentForm: StudentFormES,
  },
};
