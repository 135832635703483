import moment from 'moment';
import utils from '../../core/serviceUtils';

export default {
  namespaced: true,
  state: {
    options: [],
    fetch_at: null,
    parents_options: [],
    parents_fetch_at: null,
  },
  mutations: {
    SET_OPTIONS(state, { data }) {
      state.options = data.map((x) => {
        x.id = x.moneyflow_category_id;
        x.name = x.parent ? `${x.parent.name} / ${x.name}` : x.name;

        return x;
      }).sort((a, b) => {
        if (a.name < b.name) return -1;
        return 1;
      });
    },
    SET_FETCH_AT(state) {
      state.fetch_at = moment().valueOf();
    },
    SET_PARENTS_OPTIONS(state, { data }) {
      state.parents_options = data.map((x) => ({
        id: x.moneyflow_category_id,
        name: x.name,
      })).sort((a, b) => {
        if (a.name < b.name) return -1;
        return 1;
      });
    },
    SET_PARENTS_FETCH_AT(state) {
      state.parents_fetch_at = moment().valueOf();
    },
  },
  actions: {
    getOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.fetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('moneyflow_categories', 'get',
            { per_page: 1000 },
            ({ data }) => {
              commit('SET_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
    getParentsOptions({ commit, getters }, { cb }) {
      return new Promise(() => {
        if (getters.parentsFetchable) {
          commit('SHOW_LOADER', null, { root: true });
          utils.methods.request('moneyflow_categories', 'get',
            {
              only_parents: true,
              per_page: 1000,
            },
            ({ data }) => {
              commit('SET_PARENTS_OPTIONS', data);
            }, () => {
              commit('HIDE_LOADER', null, { root: true });
              commit('SET_PARENTS_FETCH_AT');
              if (cb) cb();
            });
        } else if (cb) cb();
      });
    },
  },
  getters: {
    fetchable(state) {
      return !state.fetch_at || moment().valueOf() > state.fetch_at + (30 * 1000);
    },
    parentsFetchable(state) {
      return !state.parents_fetch_at || moment().valueOf() > state.parents_fetch_at + (30 * 1000);
    },
    options(state) {
      return state.options;
    },
    parentsOptions(state) {
      return state.parents_options;
    },
  },
};
