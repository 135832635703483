const DashboardLayout = () => import('@/pages/Dashboard/Layout/DashboardLayout.vue');
const AuthLayout = () => import('@/pages/Auth/AuthLayout.vue');
const Login = () => import('@/pages/Auth/Login.vue');
const ActivateUser = () => import('@/pages/Auth/ActivateUser.vue');
const Admin = () => import('@/pages/Dashboard/Admin/Admin');
const Users = () => import('@/pages/Dashboard/Admin/Users/Users');
const Departments = () => import('@/pages/Dashboard/Admin/Departments/Departments');
const Roles = () => import('@/pages/Dashboard/Admin/Roles/Roles');
const MoneyflowCategories = () => import('@/pages/Dashboard/Admin/Moneyflow/MoneyflowCategories');
const Academic = () => import('@/pages/Dashboard/Academic/Academic');
const Marketing = () => import('@/pages/Dashboard/Sales/Sales');
const Financial = () => import('@/pages/Dashboard/Financial/Financial');
const Reports = () => import('@/pages/Dashboard/Reports/Reports');
const App = () => import('@/pages/Dashboard/App/App');
const ClockingSystem = () => import('@/pages/Dashboard/ClockingSystem/ClockingSystem');
const Students = () => import('@/pages/Dashboard/Academic/Students/Students');
const Programmes = () => import('@/pages/Dashboard/Academic/Programmes/Programmes');
const PushNotifications = () => import('@/pages/Dashboard/App/PushNotifications');
const Notifications = () => import('@/pages/Dashboard/App/Notifications');
const News = () => import('@/pages/Dashboard/App/News');
const Activities = () => import('@/pages/Dashboard/App/Activities');
const Requests = () => import('@/pages/Dashboard/App/Requests');
const OutflowInflow = () => import('@/pages/Dashboard/Reports/OutflowInflow');
const SalesPerCountry = () => import('@/pages/Dashboard/Reports/SalesPerCountry');
const InsuranceReport = () => import('@/pages/Dashboard/Reports/Insurances');
const SourceOfSale = () => import('@/pages/Dashboard/Reports/SourceOfSale');
const ScheduledPayments = () => import('@/pages/Dashboard/Financial/ScheduledPayments');
const MoneyOutflow = () => import('@/pages/Dashboard/Financial/MoneyOutflow');
const MoneyInflow = () => import('@/pages/Dashboard/Financial/MoneyInflow');
const Packages = () => import('@/pages/Dashboard/Sales/Packages/Packages');
const UsersForm = () => import('@/pages/Dashboard/Admin/Users/UsersForm');
const DepartmentsForm = () => import('@/pages/Dashboard/Admin/Departments/DepartmentsForm');
const RolesForm = () => import('@/pages/Dashboard/Admin/Roles/RolesForm');
const MoneyflowForm = () => import('@/pages/Dashboard/Admin/Moneyflow/MoneyflowForm');
const StudentResume = () => import('@/pages/Dashboard/Academic/Students/StudentResume');
const StudentForm = () => import('@/pages/Dashboard/Academic/Students/StudentForm');
const StudentProfile = () => import('@/pages/Dashboard/Academic/Students/StudentProfile');
const StudentProgrammes = () => import('@/pages/Dashboard/Academic/Students/StudentProgrammes');
const ProgramForm = () => import('@/pages/Dashboard/Academic/Programmes/ProgramForm');
const PackagePayments = () => import('@/pages/Dashboard/Sales/Packages/PackagePayments');
const Attendances = () => import('@/pages/Dashboard/Academic/Attendances/Attendances');
const EditAttendance = () => import('@/pages/Dashboard/Academic/Attendances/EditAttendance');
const Lock = () => import('@/pages/Template/Pages/Lock');
const Classrooms = () => import('@/pages/Dashboard/Academic/Classrooms/Classrooms');
const ClassroomsForm = () => import('@/pages/Dashboard/Academic/Classrooms/ClassroomsForm');
const Teachers = () => import('@/pages/Dashboard/Academic/Teachers/Teachers');
const TeachersForm = () => import('@/pages/Dashboard/Academic/Teachers/TeachersForm');
const PaymentMethods = () => import('@/pages/Dashboard/Admin/Payments/PaymentMethods');
const PaymentMethodsForm = () => import('@/pages/Dashboard/Admin/Payments/PaymentMethodsForm');
const Assessments = () => import('@/pages/Dashboard/Academic/Assessments/Assessments');
const AssessmentsForm = () => import('@/pages/Dashboard/Academic/Assessments/AssessmentsForm');
const StudentFiles = () => import('@/pages/Dashboard/Academic/Students/StudentFiles');
const ContingencyMap = () => import('@/pages/Dashboard/Academic/ContingencyMap/ContingencyMap');
const HolidayDays = () => import('@/pages/Dashboard/Admin/HolidayDays/HolidayDays');
const HolidayDaysForm = () => import('@/pages/Dashboard/Admin/HolidayDays/HolidayDaysForm');
const AttendanceReport = () => import('@/pages/Dashboard/Reports/Attendances');
const IncomeProyection = () => import('@/pages/Dashboard/Reports/IncomeProyection');
const UserProfile = () => import('@/pages/Dashboard/User/UserProfile');
const PlacementTest = () => import('@/pages/Dashboard/PlacementTest/PlacementTest');
const PlacementQuestions = () => import('@/pages/Dashboard/PlacementTest/Questions/PlacementQuestions');
const PlacementExams = () => import('@/pages/Dashboard/PlacementTest/Exams/PlacementExams');
const PlacementSchedules = () => import('@/pages/Dashboard/PlacementTest/Schedules/PlacementSchedules');
const Templates = () => import('@/pages/Dashboard/Admin/Templates/Templates');
const LetterTemplates = () => import('@/pages/Dashboard/Admin/LetterTemplates/Letters');
const PackageItems = () => import('@/pages/Dashboard/Admin/PackageItems/PackageItems');
const MarketingSources = () => import('@/pages/Dashboard/Admin/MarketingSources/MarketingSources');
const Dashboard = () => import('@/pages/Dashboard/Dashboard/Dashboard');
const FinishedStudentsReport = () => import('@/pages/Dashboard/Reports/FinishedStudents');
const StudentTimeline = () => import('@/pages/Dashboard/Academic/Students/StudentTimeline');
const PublicProfile = () => import('@/pages/PublicProfile');
const ExamTypes = () => import('@/pages/Dashboard/Admin/ExamTypes/ExamTypes');

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home',
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/verify_letter/:token',
        component: () => import('@/pages/LettersVerificator/LettersVerificator'),
        name: 'LetterVerification',
        meta: {
          topbar: 'Letter Verification',
        },
      },
      {
        path: '/student-form/:token',
        name: 'StudentForm',
        component: () => import('@/pages/StudentForm/StudentForm'),
        meta: {
          topbar: 'New Student Form',
        },
      },
      {
        path: '/payment/:token',
        name: 'NewPayment',
        component: () => import('@/pages/Payments/NewPayment'),
        meta: {
          topbar: ' ',
        },
      },
      {
        path: '/payment/:token/success',
        name: 'SuccessPayment',
        component: () => import('@/pages/Payments/SuccessPayment'),
        meta: {
          topbar: ' ',
        },
      },
      {
        path: '/terms_conditions/accepted/:programme_id',
        name: 'SignRequestAccepted',
        component: () => import('@/pages/StudentForm/AcceptedSignRequest'),
        meta: {
          topbar: 'Terms & Conditions',
        },
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
      {
        path: '/activate_user/:verification_code',
        name: 'ActivateUser',
        component: ActivateUser,
      },
      {
        path: '/lock',
        name: 'Lock',
        component: Lock,
      },
      {
        path: '/activate_app_account/:token',
        name: 'ActivateAppAccount',
        component: () => import('@/pages/Auth/ActivateAppAcount.vue'),
      },
    ],
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'admin',
        component: Admin,
        children: [
          {
            path: 'users',
            name: 'Users',
            component: Users,
          },
          {
            path: 'templates',
            name: 'Templates',
            component: Templates,
          },
          {
            path: 'marketing_sources',
            name: 'MarketingSources',
            meta: {
              topbar: 'Sales Sources',
            },
            component: MarketingSources,
          },
          {
            path: 'letter_templates',
            name: 'LetterTemplates',
            meta: {
              topbar: 'Letter Templates',
            },
            component: LetterTemplates,
          },
          {
            path: 'package_items',
            name: 'PackageItems',
            meta: {
              topbar: 'Package Items',
            },
            component: PackageItems,
          },
          {
            path: 'users/new',
            name: 'NewUser',
            component: UsersForm,
            meta: {
              topbar: 'New User',
            },
          },
          {
            path: 'users/:id',
            name: 'EditUser',
            component: UsersForm,
            meta: {
              topbar: 'Edit User',
            },
          },
          {
            path: 'departments',
            name: 'Departments',
            component: Departments,
          },
          {
            path: 'departments/new',
            name: 'NewDepartment',
            component: DepartmentsForm,
            meta: {
              topbar: 'New Department',
            },
          },
          {
            path: 'departments/:id',
            name: 'EditDepartment',
            component: DepartmentsForm,
            meta: {
              topbar: 'Edit Department',
            },
          },
          {
            path: 'holidays',
            name: 'HolidayDays',
            component: HolidayDays,
            meta: {
              topbar: 'Holidays',
            },
          },
          {
            path: 'holidays/new',
            name: 'NewHolidayDays',
            component: HolidayDaysForm,
            meta: {
              topbar: 'New Holiday',
            },
          },
          {
            path: 'holidays/:id',
            name: 'EditHolidayDay',
            component: HolidayDaysForm,
            meta: {
              topbar: 'Edit Holiday',
            },
          },
          {
            path: 'roles',
            name: 'Roles',
            component: Roles,
          },
          {
            path: 'roles/new',
            name: 'NewRole',
            component: RolesForm,
            meta: {
              topbar: 'New Role',
            },
          },
          {
            path: 'roles/:id',
            name: 'EditRole',
            component: RolesForm,
            meta: {
              topbar: 'Edit Role',
            },
          },
          {
            path: 'finance_categories',
            name: 'MoneyflowCategories',
            component: MoneyflowCategories,
            meta: {
              topbar: 'Finance Categories',
            },
          },
          {
            path: 'moneyflow_categories/new',
            name: 'NewMoneyflowCategory',
            component: MoneyflowForm,
            meta: {
              topbar: 'New Finance Category',
            },
          },
          {
            path: 'moneyflow_categories/:id',
            name: 'EditMoneyflowCategory',
            component: MoneyflowForm,
            meta: {
              topbar: 'Edit Moneyflow Category',
            },
          },
          {
            path: 'payment_methods',
            name: 'PaymentMethods',
            component: PaymentMethods,
            meta: {
              topbar: 'Payment Methods',
            },
          },
          {
            path: 'payment_methods/new',
            name: 'NewPaymentMethod',
            component: PaymentMethodsForm,
            meta: {
              topbar: 'New Payment Method',
            },
          },
          {
            path: 'payment_methods/:id',
            name: 'EditPaymentMethod',
            component: PaymentMethodsForm,
            meta: {
              topbar: 'Edit Payment Method',
            },
          },
          {
            path: 'system',
            name: 'SystemParameters',
            component: () => import('@/pages/Dashboard/Admin/System/System.vue'),
            meta: {
              topbar: 'System Parameters',
            },
          },
          {
            path: 'courses',
            name: 'Courses',
            component: () => import('@/pages/Dashboard/Admin/Courses/Courses.vue'),
            meta: {
              topbar: 'Courses',
            },
          },
          {
            path: 'cancel_reasons',
            name: 'CancelReasons',
            component: () => import('@/pages/Dashboard/Admin/CancelReasons/CancelReasons.vue'),
            meta: {
              topbar: 'Cancel Reasons',
            },
          },
          {
            path: 'class_schedules',
            name: 'ClassSchedules',
            component: () => import('@/pages/Dashboard/Admin/ClassSchedules/ClassSchedules.vue'),
            meta: {
              topbar: 'Class Schedules',
            },
          },
          {
            path: 'machines_ips',
            name: 'MachinesIPs',
            component: () => import('@/pages/Dashboard/Admin/MachinesIPs/MachinesIPs.vue'),
            meta: {
              topbar: 'Machines IPs',
            },
          },
          {
            path: 'buildings',
            name: 'Buildings',
            component: () => import('@/pages/Dashboard/Admin/Buildings/Buildings.vue'),
            meta: {
              topbar: 'Buildings',
            },
          },
          {
            path: 'custom_files_upload',
            name: 'CustomFilesUpload',
            component: () => import('@/pages/Dashboard/Admin/CustomFilesUpload/CustomFilesUpload.vue'),
            meta: {
              topbar: 'Custom Files Upload',
            },
          },
          {
            path: 'agent_transfer',
            name: 'AgentTransfer',
            component: () => import('@/pages/Dashboard/Admin/AgentTransfer/AgentTransfer.vue'),
            meta: {
              topbar: 'Transfer Agents',
            },
          },
          {
            path: 'school_settings',
            name: 'SchoolSettings',
            component: () => import('@/pages/Dashboard/Admin/SchoolSettings/SchoolSettings.vue'),
            meta: {
              topbar: 'School Settings',
            },
          },
          {
            path: 'exam_types',
            name: 'ExamTypes',
            component: ExamTypes,
            meta: {
              topbar: 'Exam Types',
            },
          },
        ],
      },
      {
        path: 'academic',
        component: Academic,
        children: [
          {
            path: 'teachers',
            name: 'Teachers',
            component: Teachers,
            meta: {
              topbar: 'Teachers',
            },
          },
          {
            path: 'teachers/new',
            name: 'NewTeacher',
            component: TeachersForm,
            meta: {
              topbar: 'New Teacher',
            },
          },
          {
            path: 'teachers/:id',
            name: 'EditTeacher',
            component: TeachersForm,
            meta: {
              topbar: 'Edit Teacher',
            },
          },
          {
            path: 'students',
            name: 'Students',
            component: Students,
          },
          {
            path: 'students/new',
            name: 'NewStudent',
            component: StudentForm,
            meta: {
              topbar: 'New Student',
            },
          },
          {
            path: 'students/:id',
            name: 'EditStudent',
            component: StudentForm,
            meta: {
              topbar: 'Edit Student',
            },
          },
          {
            path: 'students/:id/resume',
            name: 'StudentResume',
            component: StudentResume,
            meta: {
              topbar: 'Student Resume',
            },
          },
          {
            path: 'students/:id/profile',
            name: 'StudentProfile',
            component: StudentProfile,
            meta: {
              topbar: 'Student Profile',
            },
          },
          {
            path: 'students/:id/programmes',
            name: 'StudentProgrammes',
            component: StudentProgrammes,
            meta: {
              topbar: 'Student Programmes',
            },
          },
          {
            path: 'students/:id/programmes/new',
            name: 'NewStudentProgramme',
            component: StudentForm,
            meta: {
              topbar: 'New Student Programme',
            },
          },
          {
            path: 'students/:id/financial',
            name: 'StudentFinancial',
            component: StudentForm,
            meta: {
              topbar: 'Student Financial',
            },
          },
          {
            path: 'students/:id/files',
            name: 'StudentFiles',
            component: StudentFiles,
            meta: {
              topbar: 'Student Files',
            },
          },
          {
            path: 'students/:id/commissions',
            name: 'StudentCommissions',
            component: () => import('@/pages/Dashboard/Academic/Students/StudentsCommissions.vue'),
            meta: {
              topbar: 'Student Commissions',
            },
          },
          {
            path: 'programmes',
            name: 'Programmes',
            component: Programmes,
          },
          {
            path: 'programmes/new',
            name: 'NewProgramme',
            component: ProgramForm,
            meta: {
              topbar: 'New Programme',
            },
          },
          {
            path: 'programmes/:id',
            name: 'ProgrammeDetails',
            component: ProgramForm,
            meta: {
              topbar: 'Programme Details',
            },
          },
          // Attendance
          {
            path: 'attendances',
            name: 'Attendances',
            component: Attendances,
          },
          {
            path: 'attendances/:id',
            name: 'AttendanceDetails',
            component: EditAttendance,
            meta: {
              topbar: 'Edit Attendance',
            },
          },
          // Assessments
          {
            path: 'assessments',
            name: 'Assessments',
            component: Assessments,
          },
          {
            path: 'assessments/new',
            name: 'NewAssessment',
            component: AssessmentsForm,
            meta: {
              topbar: 'New Assessment',
            },
          },
          {
            path: 'assessments/:id',
            name: 'AssessmentDetails',
            component: AssessmentsForm,
            meta: {
              topbar: 'Assessment Details',
            },
          },
          // Classrooms
          {
            path: 'classrooms',
            name: 'Classrooms',
            component: Classrooms,
          },
          {
            path: 'classrooms/new',
            name: 'NewClassroom',
            component: ClassroomsForm,
            meta: {
              topbar: 'New Classroom',
            },
          },
          {
            path: 'classrooms/:id',
            name: 'ClassroomDetails',
            component: ClassroomsForm,
            meta: {
              topbar: 'Classroom Details',
            },
          },
          // Contingency Map
          {
            path: 'contingency_map',
            name: 'ContingencyMap',
            component: ContingencyMap,
            meta: {
              topbar: 'Contingency Map',
            },
          },
          {
            path: 'students/:id/timeline',
            name: 'StudentTimeline',
            component: StudentTimeline,
            meta: {
              topbar: 'Student Timeline',
            },
          },
        ],
      },
      {
        path: 'crm',
        component: Marketing,
        children: [
          {
            path: 'segments',
            name: 'Segments',
            component: () => import('@/pages/Dashboard/CRM/Segments/Segments'),
          },
          {
            path: 'campaigns',
            name: 'Campaigns',
            component: () => import('@/pages/Dashboard/CRM/Campaigns/Campaigns'),
          },
          {
            path: 'pipeline',
            name: 'Pipelines',
            component: () => import('@/pages/Dashboard/CRM/Pipelines/Pipelines'),
          },
          {
            path: 'pipeline_leads',
            name: 'PipelineLeads',
            component: () => import('@/pages/Dashboard/CRM/Pipelines/PipelineLeads'),
          },
        ],
      },
      {
        path: 'sales',
        component: Marketing,
        children: [
          {
            path: 'commissions',
            name: 'Commissions',
            component: () => import('@/pages/Dashboard/Sales/SalesCommissions.vue'),
          },
          {
            path: 'agencies',
            name: 'Agencies',
            component: () => import('@/pages/Dashboard/Admin/Agencies/Agencies.vue'),
            meta: {
              topbar: 'Agencies',
            },
          },
          {
            path: 'packages',
            name: 'Packages',
            component: Packages,
          },
          {
            path: 'packages/:id/payments',
            name: 'PackagePayments',
            component: PackagePayments,
            meta: {
              topbar: 'Package Payments',
            },
          },
          {
            path: 'packages/:id',
            name: 'PackageDetails',
            component: StudentForm,
            meta: {
              topbar: 'Package Details',
            },
          },
          {
            path: 'forms',
            name: 'Forms',
            component: () => import('@/pages/Dashboard/Sales/Forms/FormsIndex.vue'),
          },
          {
            path: 'agent_groups',
            name: 'AgentGroups',
            meta: {
              topbar: 'Agent Groups',
            },
            component: () => import('@/pages/Dashboard/Sales/AgentGroups/AgentGroups.vue'),
          },
          {
            path: 'student_letters',
            name: 'StudentLetters',
            meta: {
              topbar: 'Student Letters',
            },
            component: () => import('@/pages/Dashboard/Sales/StudentLetters.vue'),
          },
        ],
      },
      {
        path: 'financial',
        component: Financial,
        children: [
          {
            path: 'scheduled_payments',
            name: 'ScheduledPayments',
            component: ScheduledPayments,
            meta: {
              topbar: 'Scheduled Payments',
            },
          },
          {
            path: 'money_outflow',
            name: 'MoneyOutflow',
            component: MoneyOutflow,
            meta: {
              topbar: 'Expenditure',
            },
          },
          {
            path: 'money_inflow',
            name: 'MoneyInflow',
            component: MoneyInflow,
            meta: {
              topbar: 'Income',
            },
          },
          {
            path: 'commissions',
            name: 'FinanceCommissions',
            component: () => import('@/pages/Dashboard/Financial/Commissions'),
            meta: {
              topbar: 'Submitted Commissions',
            },
          },
        ],
      },
      {
        path: 'reports',
        component: Reports,
        children: [
          {
            path: 'assessments',
            name: 'AssessmentsReport',
            component: () => import('@/pages/Dashboard/Reports/Assessments'),
            meta: {
              topbar: 'Assessments Weekly report',
            },
          },
          {
            path: 'agencies',
            name: 'AgenciesReport',
            component: () => import('@/pages/Dashboard/Reports/Agencies'),
            meta: {
              topbar: 'Agencies',
            },
          },
          {
            path: 'application_forms',
            name: 'ApplicationFormsReport',
            component: () => import('@/pages/Dashboard/Reports/ApplicationForms.vue'),
            meta: {
              topbar: 'Application Forms',
            },
          },
          {
            path: 'package_items',
            name: 'PackageItemsReport',
            component: () => import('@/pages/Dashboard/Reports/PackageItems.vue'),
            meta: {
              topbar: 'Package Items',
            },
          },
          {
            path: 'irp',
            name: 'IrpReport',
            component: () => import('@/pages/Dashboard/Reports/IRP.vue'),
            meta: {
              topbar: 'IRP',
            },
          },
          {
            path: 'placement_test',
            name: 'PlacementTestReport',
            component: () => import('@/pages/Dashboard/Reports/PlacementTests.vue'),
            meta: {
              topbar: 'Placement Test',
            },
          },
          {
            path: 'outflow_inflow',
            name: 'OutflowInflow',
            component: OutflowInflow,
            meta: {
              topbar: 'Income Expenditure',
            },
          },
          {
            path: 'income_projection',
            name: 'IncomeProyection',
            component: IncomeProyection,
            meta: {
              topbar: 'Income Projection',
            },
          },
          {
            path: 'sales_per_country',
            name: 'SalesPerCountry',
            component: SalesPerCountry,
            meta: {
              topbar: 'Sales per Country',
            },
          },
          {
            path: 'source_of_sale',
            name: 'SourceOfSale',
            component: SourceOfSale,
            meta: {
              topbar: 'Source of Sales',
            },
          },
          {
            path: 'attendance',
            name: 'AttendanceReport',
            component: AttendanceReport,
            meta: {
              topbar: 'Attendance Report',
            },
          },
          {
            path: 'attendance/by_date',
            name: 'AttendanceByDateReport',
            component: () => import('@/pages/Dashboard/Reports/AttendancesByDate.vue'),
            meta: {
              topbar: 'Attendance By Date Report',
            },
          },
          {
            path: 'insurance',
            name: 'InsuranceReport',
            component: InsuranceReport,
            meta: {
              topbar: 'Insurance Report',
            },
          },
          {
            path: 'finished_students',
            name: 'FinishedStudentsReport',
            component: FinishedStudentsReport,
            meta: {
              topbar: 'Finished Students Report',
            },
          },
          {
            path: 'programmes',
            name: 'ProgrammesReport',
            component: () => import('@/pages/Dashboard/Reports/Programmes.vue'),
            meta: {
              topbar: 'Programmes Report',
            },
          },
          {
            path: 'students',
            name: 'StudentsReport',
            component: () => import('@/pages/Dashboard/Reports/Students.vue'),
            meta: {
              topbar: 'Students Report',
            },
          },
          {
            path: 'students_exam',
            name: 'StudentsExamReport',
            component: () => import('@/pages/Dashboard/Reports/StudentsExams.vue'),
            meta: {
              topbar: 'Students Exams Report',
            },
          },
          {
            path: 'end_exam',
            name: 'EndExam',
            component: () => import('@/pages/Dashboard/Reports/EndExam.vue'),
            meta: {
              topbar: 'End Exam Report',
            },
          },
          {
            path: 'student_accommodation',
            name: 'studentAccommodation',
            component: () => import('@/pages/Dashboard/Reports/StudentsAccommodation.vue'),
            meta: {
              topbar: 'Students Accommodations Report',
            },
          },
          {
            path: 'cancelled_programmes',
            name: 'cancelledProgrammes',
            component: () => import('@/pages/Dashboard/Reports/CancelledProgrammes.vue'),
            meta: {
              topbar: 'Cancelled Programmes',
            },
          },
        ],
      },
      {
        path: 'clocking_system',
        component: ClockingSystem,
        children: [
          {
            path: 'students_records',
            name: 'StudentsRecords',
            component: () => import('@/pages/Dashboard/ClockingSystem/StudentsRecords'),
            meta: {
              topbar: 'Students Time Records',
            },
          },
          {
            path: 'staff_records',
            name: 'StaffRecords',
            component: () => import('@/pages/Dashboard/ClockingSystem/StaffRecords'),
            meta: {
              topbar: 'Staff Time Records',
            },
          },
          {
            path: 'machines_map',
            name: 'MachinesMap',
            component: () => import('@/pages/Dashboard/ClockingSystem/MachinesMap'),
            meta: {
              topbar: 'Machines Map',
            },
          },
        ],
      },
      {
        path: 'app',
        component: App,
        children: [
          {
            path: 'about_us',
            name: 'AboutUs',
            component: () => import('@/pages/Dashboard/App/AboutUs.vue'),
            meta: {
              topbar: 'About Us',
            },
          },
          {
            path: 'calendar',
            name: 'Calendar',
            component: () => import('@/pages/Dashboard/App/Calendar.vue'),
            meta: {
              topbar: 'Calendar',
            },
          },
          {
            path: 'push_notifications',
            name: 'PushNotifications',
            component: PushNotifications,
            meta: {
              topbar: 'Push Notifications',
            },
          },
          {
            path: 'notifications',
            name: 'Notifications',
            component: Notifications,
          },
          {
            path: 'news',
            name: 'News',
            component: News,
          },
          {
            path: 'activities',
            name: 'Activities',
            component: Activities,
          },
          {
            path: 'requests',
            name: 'Requests',
            component: Requests,
          },
          {
            path: 'request_types',
            name: 'RequestTypes',
            component: () => import('@/pages/Dashboard/App/RequestTypes'),
          },
          {
            path: 'banners',
            name: 'Banners',
            component: () => import('@/pages/Dashboard/App/Banners'),
          },
        ],
      },
      {
        path: 'placement_test',
        component: PlacementTest,
        children: [
          {
            path: 'questions',
            component: PlacementQuestions,
            name: 'PlacementQuestions',
            meta: {
              topbar: 'Placement Test - Questions',
            },
          },
          {
            path: 'exams',
            component: PlacementExams,
            name: 'PlacementExams',
            meta: {
              topbar: 'Placement Test - Exams',
            },
          },
          {
            path: 'schedules',
            component: PlacementSchedules,
            name: 'PlacementSchedules',
            meta: {
              topbar: 'Placement Test - Schedules',
            },
          },
        ],
      },
      {
        path: 'progress_exit_report',
        component: () => import('@/components/RouterParent.vue'),
        children: [
          {
            path: 'questions',
            component: () => import('@/pages/Dashboard/ExitReport/Questions.vue'),
            name: 'PERQuestions',
            meta: {
              topbar: 'Exit Report - Questions',
            },
          },
          {
            path: 'schedules',
            component: () => import('@/pages/Dashboard/ExitReport/Schedules.vue'),
            name: 'PERSchedules',
            meta: {
              topbar: 'Exit Report - Schedules',
            },
          },
        ],
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: UserProfile,
        meta: {
          topbar: 'User Profile',
        },
      },
    ],
  },
  {
    path: '/public-profile',
    component: PublicProfile,
  },
  {
    path: '/*',
    component: () => import('@/pages/404.vue'),
  },
];

export default routes;
